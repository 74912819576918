<template>
    <div class="container">
        <vue-galery :id="`galery-${subfolder}`" :images="images" :index="index" @close="index = null"></vue-galery>
        <div class="thumbnail" v-for="(img, imageIndex) of this.images" 
            :key="imageIndex" 
            @click="img !== '_PLACEHOLDER' && (index = imageIndex)"
            :class="{'placeholder':  img === '_PLACEHOLDER'}"
            :style="[img !== '_PLACEHOLDER' && { backgroundImage: `url(${img})`}]"></div>
    </div>
</template>

<script>
    import VueGalery from 'vue-gallery';

    export default {
        name: 'image-galery',
        props: ['subfolder'],
        components: {
            'vue-galery': VueGalery
        },
        data: function() {
            return {
                images: [],
                index: null
            }
        },
        mounted() {
            // eslint-disable-next-line no-console
            const images = require.context('../../assets/', true, /\.jpg$/);
            const imageURLs = images.keys().filter((url) => url.startsWith(`./${this.subfolder}`));

            imageURLs.forEach((element) => {
                this.images.push(images(element));
            });

            if (this.images.length % 3 == 2) {
                this.images.push('_PLACEHOLDER');
            }
        }
    }
</script>

<style scoped>
    .container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: -20px;
    }

    .thumbnail {
        width: 400px;
        height: 300px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        width: 30%;
        border: 2px solid var(--accent);
        margin-top: 40px;
    }

    .thumbnail.placeholder {
        border: none;
    }

    .hidden {
        display: none;
    }

    @media (max-width: 920px) {
        .thumbnail {
            width: 46%;
        }

        .thumbnail.placeholder {
            display: none;
        }
    }

    @media (max-width: 780px) {
        .thumbnail {
            width: 100%;
            height: 300px;
        }

        .container {
            margin-top: 0;
        }

        .thumbnail {
            margin-top: 20px; 
        }

        .thumbnail.placeholder {
            display: none;
        }
    }
</style>
